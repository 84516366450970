import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_thumbnail = _resolveComponent("thumbnail")!
  const _component_inventory_risk_icons = _resolveComponent("inventory-risk-icons")!
  const _component_b_advanced_table = _resolveComponent("b-advanced-table")!
  const _component_screen = _resolveComponent("screen")!

  return (_openBlock(), _createBlock(_component_screen, { full: "" }, {
    default: _withCtx(() => [
      _createVNode(_component_b_advanced_table, {
        "table-array": [_ctx.table],
        "sticky-header": "calc(100vh - 105px)",
        loading: _ctx.state.loading,
        "dynamic-columns": ""
      }, {
        "cell(imageUrlThumb)": _withCtx((row) => [
          _createVNode(_component_thumbnail, {
            "image-url-thumb": row.item.imageUrlThumb,
            "image-url-full": row.item.imageUrlFull
          }, null, 8, ["image-url-thumb", "image-url-full"])
        ]),
        "cell(riskScore)": _withCtx((row) => [
          _createVNode(_component_inventory_risk_icons, {
            "has-negative-finished-good-inventory": row.item.quantityOnHand < 0,
            risk: row.item.riskFlag
          }, null, 8, ["has-negative-finished-good-inventory", "risk"])
        ]),
        _: 1
      }, 8, ["table-array", "loading"])
    ]),
    _: 1
  }))
}