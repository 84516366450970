
import {
    computed, defineComponent, onBeforeMount, reactive,
} from 'vue';
import { TableDefinition } from '@/types';
import InventoryService from '@/services/InventoryService';
import coreStore from '@/store/CoreStore';
import InventoryRiskIcons from '@/components/InventoryRiskIcons.vue';
import RiskFlag from '@/domain/enums/RiskFlag';
import InventoryGroupedDTO from '@/dtos/InventoryGroupedDTO';
import Screen from '@/components/layout/Screen.vue';
import BAdvancedTable from '@/components/bootstrap-library/table/BAdvancedTable.vue';
import Thumbnail from '@/components/Thumbnail.vue';
import { getTitleCaseTranslation } from '@/services/TranslationService';

type InventoryTableData = {
    locationName: string;
    itemId: number;
    itemName: string;
    quantityOnHand: number;
    quantityInbound: number;
    quantityOutbound: number;
    riskFlag: RiskFlag;
    riskScore?: number;
    imageUrlThumb?: string;
    imageUrlFull?: string;
};

type State = {
    inventoryRows: Array<InventoryTableData>;
    loading: boolean;
};

export default defineComponent({
    name: 'inventory-list',
    components: {
        Thumbnail,
        BAdvancedTable,
        Screen,
        InventoryRiskIcons,
    },
    setup() {
        const inventoryService = new InventoryService();
        const { profileStore, inventoryCategoryStore } = coreStore.getInstance();
        const finishedGoodsCategory = inventoryCategoryStore.getFinishedGoodsCategory();

        const state = reactive<State>({
            inventoryRows: [],
            loading: true,
        });

        function mapInventoryToTableData(data: InventoryGroupedDTO): InventoryTableData {
            return {
                locationName: data.locationName,
                itemId: data.itemId,
                itemName: data.itemName,
                quantityOnHand: finishedGoodsCategory ? data.getInventoryForCategory(finishedGoodsCategory) : 0,
                quantityInbound: data.quantityInbound,
                quantityOutbound: data.quantityOutbound,
                riskFlag: data.riskFlag,
                riskScore: data.riskScore,
                imageUrlThumb: data.imageUrlThumb,
                imageUrlFull: data.imageUrlFull,
            };
        }

        onBeforeMount(async () => {
            const response = await inventoryService.getAllLocationInventory(profileStore.userLocation.id);
            if (response.success) {
                state.inventoryRows = response.inventory?.map((data) => mapInventoryToTableData(data));
                state.loading = false;
            }
        });

        const table = computed(
            (): TableDefinition<InventoryTableData> => ({
                items: state.inventoryRows,
                key: 'inventoryList',
                name: getTitleCaseTranslation('core.domain.inventory'),
                columnDefinition: [
                    {
                        key: 'imageUrlThumb',
                        label: getTitleCaseTranslation('core.common.image'),
                    },
                    {
                        key: 'riskScore',
                        label: getTitleCaseTranslation('core.common.flag'),
                        width: '75px',
                    },
                    {
                        key: 'locationName',
                        searchable: true,
                        label: getTitleCaseTranslation('core.domain.location'),
                    },
                    {
                        key: 'itemId',
                        searchable: true,
                        label: getTitleCaseTranslation('core.domain.containerNumber'),
                    },
                    {
                        key: 'itemName',
                        searchable: true,
                        label: getTitleCaseTranslation('core.domain.containerName'),
                    },
                    {
                        key: 'quantityOnHand',
                        searchable: true,
                        label: getTitleCaseTranslation('core.domain.quantityOnHand'),
                    },
                    {
                        key: 'quantityInbound',
                        searchable: true,
                        label: getTitleCaseTranslation('core.domain.inbound'),
                    },
                    {
                        key: 'quantityOutbound',
                        searchable: true,
                        label: getTitleCaseTranslation('core.domain.outbound'),
                    },
                ],
            }),
        );

        return {
            state,
            table,
        };
    },
});
